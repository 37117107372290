<template>
  <div class="autocomplete">
    <label
      class="autocomplete__label"
      :class="{ 'active': showFloatingLabel }"
      v-show="!hideLabel"
    >
      {{ label }}
      <span v-show="requiredField" class="required-field">*</span>
    </label>
    <el-form :model="{ localValue }" :rules="rules" ref="form">
      <el-form-item prop="localValue">
        <el-select
          :class="disabledSearch ? 'disabled' : ''"
          v-model="localValue"
          filterable
          remote
          :disabled="disabled"
          :placeholder="placeholderVisible ? label : null"
          :remote-method="handleSearch"
          :popper-append-to-body="false"
          :loading="loading"
          :value-key="valueKey"
          name="localValue"
          @change="handleChange"
          @visible-change="handleVisibleChange"
          @focus="handleFocus"
          class="autocomplete__select"
          ref="autocomplete"
          v-cancel-read-only
        >
          <template slot="prefix" v-if="showPrefixIcon">
            <div class="autocomplete__prefix">
              <slot name="icon" v-if="hasIconSlot"></slot>
              <b-icon icon="search" class="icon-prefix" v-else/>
            </div>
          </template>
          <el-option
            key="add_new"
            label="Add new"
            :value="false"
            disabled
            v-if="actionPerformed"
          >
            <slot name="action-in-option">
            </slot>
          </el-option>
          <el-option
            v-if="showValueOption"
            :key="localValue[optionKey]"
            :label="localValue[optionKey]"
            :value="localValue"
          />
          <template v-if="isGrouped">
            <el-option-group
              v-for="group, index in localSuggestions"
              :key="`${group[groupKey]}_${index}`"
              :label="group[groupKey]"
              >
              <el-option
                v-for="item, index in group.options"
                :key="`${item[optionKey]}_${index}`"
                :label="type === 'pickup' && isDrayage ? item['name'] : item[optionKey]"
                :value="item"
              >
                <slot name="option-item" :option="item">
                  <div class="autocomplete__select--option" :class="{ 'hasAdditionalText': item.street }">
                    <span>{{ item.label }}</span>
                    <span class="additional-text">{{ item.street }}</span>
                  </div>
                </slot>
              </el-option>
            </el-option-group>
          </template>
          <template v-else>
            <el-option
              v-for="(item, index) in localSuggestions[0]?.options"
              :key="`${item[optionKey]}_${index}`"
              :label="item[optionKey]"
              :value="item"
            >
              <slot name="option-item" :option="item">
                <div class="autocomplete__select--option" :class="{ 'hasAdditionalText': item.street }">
                  <span>{{ item.label }}</span>
                  <span class="additional-text">{{ item.street }}</span>
                </div>
              </slot>
            </el-option>
          </template>
          <el-option
            key="loading"
            label="Loading..."
            :value="null"
            disabled
            class="loading-option"
            v-if="loadingMore"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "AutoComplete",
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    requiredField: {
      type: Boolean,
      default: false,
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    requestLength: {
      type: Number,
      default: 1,
    },
    forceSelection: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    groupKey: {
      type: String,
      default: "label",
    },
    optionKey: {
      type: String,
      default: "label",
    },
    valueKey: {
      type: String,
      default: "label"
    },
    missingField: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    showPrefixIcon: {
      type: Boolean,
      default: true,
    },
    actionPerformed: {
      type: Boolean,
      default: false,
    },
    disabledSearch: {
      type: Boolean
    },
    drayageSearch: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    isDrayage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: this.value || null,
      localSuggestions: [],
      showFloatingLabel: false,
      placeholderVisible: true,
      rules: {
        localValue: [
          {
            required: this.requiredField,
            message: " ",
            trigger: 'change'
          }
        ]
      }
    };
  },

  created() {
    this.$nextTick(() => {
      this.assignSuggestions(this.suggestions);
    });
  },

  computed: {
    hasIconSlot() {
      return !!this.$slots.icon;
    },

    showValueOption() {
      return this.localValue && this.localSuggestions.length === 0;
    },

    isGrouped() {
      const hasOwn = Object.prototype.hasOwnProperty;
      return this.localSuggestions.length > 0 && hasOwn.call(this.localSuggestions[0], this.groupKey);
    }
  },

  methods: {
    /**
     * Handles search events and emits a search event with details.
     * @memberof AutoComplete
     * @param {string} event - The search term entered.
     * @returns {void}
     */
    handleSearch(event) {
      if (this.drayageSearch) {
        this.$emit("search", { query: event, originEvent: event });
        return;
      }
      if (!event || event.length < this.requestLength) {
        return;
      }
      this.$emit("search", { query: event, originEvent: event });
    },

    /**
     * Handles changes in input or selection of options.
     * @memberof AutoComplete
     * @param {Event} event - The change event.
     * @returns {void}
     */
    handleChange(event) {
      this.$emit("input", event);
      this.$emit("selectOption", event);
      if (this.$refs.autocomplete.blur()) {
        this.$nextTick(() => {
          this.$refs.autocomplete.blur();
        });
      }
    },

    /**
     * Handles changes in the visibility of an element, such as a dropdown.
     * @memberof AutoComplete
     * @param {boolean} visible - Indicates if the element is visible.
     * @returns {void}
     */
    handleVisibleChange(visible) {
      if (visible) {
        // If the dropdown is displayed and the reference is not yet established
        this.$nextTick(() => {
          // Wait for the next tick to ensure that the DOM has been updated.
          const dropdown = document.querySelector('.el-select-dropdown .el-scrollbar__wrap');
          if (dropdown) {
            // We add a listener for the scroll event
            dropdown.addEventListener('scroll', this.handleScroll);
          }
        });
      } else {
        this.$emit("handleVisibleChange");
      }
    },

    /**
     * Handles the scroll event to detect when the end of a scrollable container is reached.
     * @memberof AutoComplete
     * @param {Event} event - The scroll event.
     * @returns {void}
     */
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        this.$emit("optionsEndReached");
      }
    },

    /**
     * Auxiliary function to toggle the visibility of labels and placeholders.
     * @memberof AutoComplete
     * @param {boolean} shouldShowFloatingLabel - Indicates whether to show the floating label.
     * @param {boolean} shouldShowPlaceholder - Indicates whether to show the placeholder.
     */
    toggleLabels(shouldShowFloatingLabel, shouldShowPlaceholder) {
      this.showFloatingLabel = shouldShowFloatingLabel;
      this.placeholderVisible = shouldShowPlaceholder;
    },

    /**
     * Handles the input field focus event.
     * If there is no local value, toggles the visibility of the floating label and the placeholder.
     * If there is a local value, ensures that the floating label is displayed.
     * This function must be called when the user focuses on the input field.
     * @function handleFocus
     * @memberof AutoComplete
     * @returns {void}
     */
    handleFocus() {
      if (!this.localValue) {
        this.toggleLabels(!this.showFloatingLabel, !this.placeholderVisible);
      } else {
        this.toggleLabels(true, this.placeholderVisible);
      }
    },

    /**
     * Hides the options dropdown of the Autocomplete component.
     * Use the handleClose() method of the Autocomplete component.
     * @function hideDropdown
     * @memberof AutoComplete
     * @returns {void}
     */
    hideDropdown() {
      this.$refs.autocomplete.handleClose();
    },

    /**
     * Assigns local suggestions based on the provided array.
     * Performs a deep copy of the suggestions array to avoid unintended mutations.
     * @function assignSuggestions
     * @memberof AutoComplete
     * @param {Array} suggestions - Array of suggestions to assign locally.
     * @returns {void}
     */
    assignSuggestions(suggestions) {
      this.localSuggestions = JSON.parse(JSON.stringify(suggestions));
    }
  },

  watch: {
    value(newValue) {
      if (newValue) {
        this.showLabel = true;
      } else {
        this.showLabel = false;
      }
      this.localValue = newValue;
    },

    suggestions: {
      deep: true,
      handler() {
        this.assignSuggestions(this.suggestions);
      }
    },

    missingField(newVal) {
      if (newVal) {
        this.$refs.form.validateField("localValue");
      } else {
        this.$refs.form.clearValidate("localValue");
      }
    },

  },

  directives: {
    /**
     * Iterate over all "el-select" elements and remove the "readonly" attribute.
     * To allow the user to type in the input field using mobile browser.
     * @function cancelReadOnly
     * @memberof AutoComplete
     * @returns {void}
     */
    cancelReadOnly(el) {
      const input = el.querySelector('.el-input__inner');
      input.removeAttribute('readonly');
    }
  }
};
</script>

<style lang="scss" scoped>
.autocomplete {
  position: relative;

  &__label {
    position: absolute;
    top: 10px;
    left: 5px;
    transition: top 0.3s, font-size 0.3s;
    pointer-events: none;
    font-size: 16px;
    color: #6c757d;
    &.active {
      top: -17px;
      font-size: 12px;
      color: #6c757d;
    }
  }

  &__select {
    width: 100%;
    &--option {
      display: flex;
      flex-direction: column;
      color: $color-primary-company;
    }
  }

  &__prefix {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    color: $color-primary-company;
    font-size: 1.6em;
  }
}

.required-field {
  color: $color-error;
}

.additional-text {
  color:#6c757d;
}

.hasAdditionalText {
  line-height: 20px;
}

.icon-prefix {
  font-size: 1rem;
  margin-left: 3px;
}

::v-deep .disabled div input {
  pointer-events: none;
}

::v-deep {
  .el-form-item {
    margin: 0px;
  }

  .el-form-item.is-error .el-input__inner{
    background-color: $missing-fields;
    border-color: $color-border-container;
  }

  .el-input {
    font-size: 16px ;

    &__inner {
      height: 40px !important;
      border-radius: 10px;
      border: 1px solid $color-border-container;
      line-height: unset;
      text-align: left;
      color: $text-primary;
      &::placeholder {
        transition: opacity 0.3s;
        opacity: 1;
      }
    }
    &--suffix .el-input__inner {
      padding-right: 0;
    }

    &__prefix {
      color: $color-shadow-sidebar;
      top: -2px;
    }

    &.is-focus .el-input__inner {
      border-color: $color-border-container;
    }
  }

  .el-select-group__title {
    font-size: 0.9rem;
    text-align: left;
    font-weight: bold;
    color: $color-border-container;
  }

  .el-select-dropdown {
    &__item {
      height: auto;
      text-align: left;
      white-space: normal;
      &.loading-option {
        text-align: center;
      }
    }
  }
}
</style>
